
.mpg-wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100% ;
    height: 100%;
    margin: auto;
}

.qrDiv{
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
}

.content{
    width:60%
}

.highlight{
    color:green;
}

.pay-button{
    margin: auto;
}

.copy{
    cursor: pointer;
}

.inline{
    display: inline;    
}

.timer{
    background:rgb(4, 65, 10);
    color:white;
    display:block;
    text-align:right;
    border-radius: 5px;
    padding: 5px;
}